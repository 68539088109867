// src/contexts/BlockchainContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import NFTStakingABI from '../contracts/NFTStaking.json';
import MockERC20ABI from '../contracts/MockERC20.json';
import MockNFTABI from '../contracts/MockNFT.json';
import MultiplierManagerABI from '../contracts/MultiplierManager.json';

export const BlockchainContext = createContext();

// Load environment variables
const NFT_STAKING_ADDRESS = process.env.REACT_APP_NFT_STAKING_ADDRESS;
const MOCK_ERC20_ADDRESS = process.env.REACT_APP_MOCK_ERC20_ADDRESS;
const MOCK_NFT_ADDRESS = process.env.REACT_APP_MOCK_NFT_ADDRESS;
const MULTIPLIER_MANAGER_ADDRESS = process.env.REACT_APP_MULTIPLIER_MANAGER_ADDRESS;

export const BlockchainProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [nftStakingContract, setNftStakingContract] = useState(null);
  const [mockERC20Contract, setMockERC20Contract] = useState(null);
  const [mockNFTContract, setMockNFTContract] = useState(null);
  const [multiplierManagerContract, setMultiplierManagerContract] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        try {
          // Request account access
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
          const tempSigner = tempProvider.getSigner();
          const tempAccount = await tempSigner.getAddress();

          setProvider(tempProvider);
          setSigner(tempSigner);
          setAccount(tempAccount);

          // Initialize contract instances
          const staking = new ethers.Contract(NFT_STAKING_ADDRESS, NFTStakingABI.abi, tempSigner);
          setNftStakingContract(staking);

          const erc20 = new ethers.Contract(MOCK_ERC20_ADDRESS, MockERC20ABI.abi, tempSigner);
          setMockERC20Contract(erc20);

          const nft = new ethers.Contract(MOCK_NFT_ADDRESS, MockNFTABI.abi, tempSigner);
          setMockNFTContract(nft);

          const multiplierManager = new ethers.Contract(MULTIPLIER_MANAGER_ADDRESS, MultiplierManagerABI.abi, tempSigner);
          setMultiplierManagerContract(multiplierManager);
        } catch (error) {
          console.error("Error connecting to MetaMask:", error);
        }
      } else {
        alert("Please install MetaMask!");
      }
    };

    init();
  }, []);

  return (
    <BlockchainContext.Provider value={{
      provider,
      signer,
      account,
      nftStakingContract,
      mockERC20Contract,
      mockNFTContract,
      multiplierManagerContract,
    }}>
      {children}
    </BlockchainContext.Provider>
  );
};

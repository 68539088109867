// src/App.js
import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ApproveAllButton from './components/ApproveAllButton';
import StakeButton from './components/StakeButton';
import ClaimButton from './components/ClaimButton';
import StakedTokensDisplay from './components/StakedTokensDisplay';
import TotalRewards from './components/TotalRewards';

// Import the font
import '@fontsource/bangers';

const theme = createTheme({
  typography: {
    fontFamily: 'Bangers, cursive',
    h1: {
      fontFamily: 'Bangers, cursive',
    },
    h2: {
      fontFamily: 'Bangers, cursive',
    },
    // Add more variants as desired
  },
  // Customize other theme properties if needed
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: 'url(/background.jpg)', // Replace with your image path
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#fff', // Adjust text color for better contrast
        }}
      >
        {/* Optional: Add a semi-transparent overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          {/* Header Section */}
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h2" gutterBottom>
            ♕ Block Sapiens ♕ NFT Staking DApp ♕
            </Typography>
          </Box>

          {/* Action Buttons Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              mb: 4,
              flexWrap: 'wrap', // Ensures responsiveness on smaller screens
            }}
          >
            <ApproveAllButton />
            <StakeButton />
            <ClaimButton />
          </Box>

          {/* Total Rewards Section */}
          <TotalRewards />

          {/* Staked Tokens Display Section */}
          <StakedTokensDisplay />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;

// src/components/StakedTokenCard.jsx
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import { Card, CardContent, Typography, CardMedia, Box, CircularProgress, Grid } from '@mui/material';
import UnstakeButton from './UnstakeButton';
import { ethers } from 'ethers'; // Ensure ethers is imported for formatting

const StakedTokenCard = ({ tokenId }) => {
  const { 
    mockNFTContract, 
    multiplierManagerContract, 
    nftStakingContract, 
    provider // Assuming provider is available in context
  } = useContext(BlockchainContext);
  
  const [image, setImage] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [multiplier, setMultiplier] = useState(100); // Default multiplier
  const [reward, setReward] = useState(ethers.BigNumber.from(0));
  const [erc20Symbol, setErc20Symbol] = useState(''); // State for ERC20 Token Symbol
  const [baseRewardRate, setBaseRewardRate] = useState(ethers.BigNumber.from(0)); // State for Base Reward Rate
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Define ERC20 Token Address (Ensure this environment variable is set)
  const ERC20_ADDRESS = process.env.REACT_APP_MOCK_ERC20_ADDRESS; 

  // ERC20 ABI (Including 'symbol' function)
  const ERC20_ABI = [
    "function symbol() view returns (string)"
  ];

  // Initialize ERC20 Contract using useMemo to prevent re-creation on every render
  const erc20Contract = useMemo(() => {
    if (!ERC20_ADDRESS || !provider) return null;
    try {
      return new ethers.Contract(ERC20_ADDRESS, ERC20_ABI, provider);
    } catch (err) {
      console.error("Error initializing ERC20 Contract:", err);
      return null;
    }
  }, [ERC20_ADDRESS, provider]);

  useEffect(() => {
    const fetchData = async () => {
      // Check if all necessary contracts and provider are loaded
      if (!mockNFTContract || !multiplierManagerContract || !nftStakingContract || !provider || !erc20Contract) {
        setError('Contracts or provider not loaded.');
        setLoading(false);
        return;
      }
      try {
        // 1. Fetch ERC20 Token Symbol
        const symbol = await erc20Contract.symbol();
        setErc20Symbol(symbol);
        console.log("ERC20 Symbol:", symbol);

        // 2. Fetch NFT Token URI (assuming ERC721 Metadata extension)
        const tokenURI = await mockNFTContract.tokenURI(tokenId);
        console.log(`Fetching metadata for Token ID ${tokenId}: ${tokenURI}`);

        // 3. Fetch Metadata from tokenURI
        const response = await fetch(tokenURI);
        if (!response.ok) {
          throw new Error(`Failed to fetch token metadata: ${response.statusText}`);
        }
        const metadata = await response.json();
        setImage(metadata.image || 'https://via.placeholder.com/300'); // Placeholder image
        setTokenName(metadata.name || `NFT #${tokenId}`); // Set token name
        console.log("Metadata fetched:", metadata);

        // 4. Fetch Multiplier
        const mult = await multiplierManagerContract.getMultiplier(tokenId);
        console.log("Multiplier fetched:", mult.toString());
        setMultiplier(mult.toNumber());

        // 5. Fetch Base Reward Rate from NFTStaking Contract
        const baseRate = await nftStakingContract.baseRewardRate();
        console.log("Base Reward Rate fetched:", baseRate.toString());
        setBaseRewardRate(baseRate);

        // 6. Fetch Staking Info
        const stakeInfo = await nftStakingContract.stakes(tokenId);
        console.log("Stake Info fetched:", stakeInfo);
        if (stakeInfo.startTime === 0) {
          setError('This token is not currently staked.');
          setLoading(false);
          return;
        }

        // 7. Calculate Staking Duration
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const stakingDuration = currentTime - stakeInfo.startTime;
        console.log("Staking Duration (seconds):", stakingDuration);

        // 8. Calculate Adjusted Reward Rate: baseRewardRate * multiplier / 100
        const adjustedRewardRate = baseRewardRate.mul(ethers.BigNumber.from(mult)).div(100);
        console.log("Adjusted Reward Rate:", adjustedRewardRate.toString());

        // 9. Calculate Reward: stakingDuration * adjustedRewardRate
        const stakingDurationBN = ethers.BigNumber.from(stakingDuration);
        const calculatedReward = stakingDurationBN.mul(adjustedRewardRate);
        console.log("Calculated Reward:", calculatedReward.toString());
        setReward(calculatedReward);
      } catch (err) {
        console.error("Error fetching token data:", err);
        setError(err.message || 'Failed to load token data.');
      }
      setLoading(false);
    };

    fetchData();
  }, [tokenId, mockNFTContract, multiplierManagerContract, nftStakingContract, erc20Contract, provider]);

  // Update reward every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const calculateCurrentReward = async () => {
        if (!nftStakingContract || !multiplierManagerContract || !baseRewardRate) {
          console.error('Contracts or baseRewardRate not loaded.');
          return;
        }
        try {
          const stakeInfo = await nftStakingContract.stakes(tokenId);
          console.log("Stake Info (update):", stakeInfo);
          if (stakeInfo.startTime === 0) {
            setError('This token is not currently staked.');
            return;
          }

          const mult = await multiplierManagerContract.getMultiplier(tokenId);
          console.log("Multiplier (update):", mult.toString());

          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
          const stakingDuration = currentTime - stakeInfo.startTime;
          console.log("Staking Duration (update):", stakingDuration);

          const adjustedRewardRate = baseRewardRate.mul(ethers.BigNumber.from(mult)).div(100);
          console.log("Adjusted Reward Rate (update):", adjustedRewardRate.toString());

          const stakingDurationBN = ethers.BigNumber.from(stakingDuration);
          const calculatedReward = stakingDurationBN.mul(adjustedRewardRate);
          console.log("Calculated Reward (update):", calculatedReward.toString());

          setReward(calculatedReward);
        } catch (err) {
          console.error("Error updating reward:", err);
          setError('Failed to update reward.');
        }
      };
      calculateCurrentReward();
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [nftStakingContract, multiplierManagerContract, tokenId, baseRewardRate]);

  if (loading) {
    return (
      <Card sx={{ width: '100%', margin: 1, padding: 2, boxShadow: 'none' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ width: '100%', margin: 1, padding: 2, boxShadow: 'none' }}>
        <Typography variant="h6" color="error" sx={{ textAlign: 'center' }}>
          {error}
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '400px', // Ensures the card doesn't exceed 400px
        margin: 1,
        boxShadow: '0 0 20px 5px rgba(0, 191, 255, 0.6)', // Neon blue shadow
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
        borderRadius: '15px',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.05)', // Slight scale on hover for interactivity
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center the content horizontally
        paddingTop: 2, // Adds offset from the top of the card
      }}
    >
      {/* Image Section */}
      <Box 
        sx={{ 
          width: '90%', 
          mb: 2, 
          borderRadius: '20px', // Rounded edges for the container
          overflow: 'hidden',    // Ensures the image respects the container's borderRadius
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)', // Optional: adds a subtle shadow to the container
        }}
      >
        <CardMedia
          component="img"
          height="250" // Adjusted height to fit within the card with padding
          image={image}
          alt={`NFT ${tokenName}`} // Descriptive alt text
          sx={{
            objectFit: 'cover',   // Changed to 'cover' for better filling
            borderRadius: '20px', // Match the container's borderRadius
            margin: '0 auto',     // Centers the image horizontally
            width: '100%',        // Ensures the image fills the container's width
            height: '100%',       // Ensures the image fills the container's height
          }}
        />
      </Box>
      
      {/* Content Section */}
      <CardContent sx={{ width: '90%', textAlign: 'center' }}>
        <Grid container spacing={1}>
          {/* Token Name */}
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Bangers, cursive',
                fontSize: {
                  xs: '1.5rem', // Smaller on extra-small screens
                  sm: '2.0rem',
                  md: '2.0rem',
                },
                color: '#00bfff', // Neon blue for titles
                whiteSpace: 'normal', // Allow text to wrap
                wordWrap: 'break-word',
              }}
            >
              {tokenName}
            </Typography>
          </Grid>

          {/* Multiplier */}
          <Grid item xs={12}>
            <Typography
              variant="body1" // Changed from 'body2' to 'body1' for slightly larger text
              color="#ffffff"
              sx={{
                fontSize: {
                  xs: '1rem', // Adjusted font size for smaller screens
                  sm: '1.1rem',
                  md: '1.3rem',
                },
                marginTop: 1, // Adds spacing above
              }}
            >
              Multiplier: ♕ {multiplier / 100}x ♕
            </Typography>
          </Grid>

          {/* Unclaimed Reward */}
          <Grid item xs={12}>
            <Typography
              variant="body1" // Changed from 'body2' to 'body1' for slightly larger text
              color="#ffffff"
              sx={{
                fontSize: {
                  xs: '1rem', // Adjusted font size for smaller screens
                  sm: '1.1rem',
                  md: '1.3rem',
                },
                marginTop: 0.5, // Adds slight spacing above
              }}
            >
              Unclaimed Reward: {parseFloat(ethers.utils.formatEther(reward)).toFixed(4)} {erc20Symbol}
            </Typography>
          </Grid>
        </Grid>

        {/* Display error message if any */}
        {error && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            {error}
          </Typography>
        )}
      </CardContent>

      {/* Unstake Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, width: '100%' }}>
        <UnstakeButton tokenId={tokenId} />
      </Box>
    </Card>
  );
};

export default StakedTokenCard;

// src/components/ClaimButton.jsx
import React, { useContext, useState } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import NeonButton from './NeonButton'; // Import the custom NeonButton
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const ClaimButton = () => {
  const { nftStakingContract, account } = useContext(BlockchainContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleClaim = async () => {
    if (!nftStakingContract) {
      setError("Staking Contract not loaded.");
      return;
    }
    setLoading(true);
    try {
      // Fetch all staked tokens
      const tokenIds = await nftStakingContract.getStakedTokens(account);
      if (tokenIds.length === 0) {
        setError('No staked NFTs to claim rewards from.');
        setLoading(false);
        return;
      }

      // Claim rewards for each token
      for (const tokenId of tokenIds) {
        const tx = await nftStakingContract.claimRewards(tokenId);
        await tx.wait();
      }
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError('Claiming Failed. Please try again.');
    }
    setLoading(false);
  };

  return (
    <>
      {/* Use the NeonButton instead of MUI's Button */}
      <NeonButton
        onClick={handleClaim}
        disabled={loading || !nftStakingContract}
        aria-label="Claim All Rewards"
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Claim All Rewards'}
      </NeonButton>

      {/* Success Snackbar */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
          variant="filled"
        >
          Rewards Claimed Successfully!
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError('')}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClaimButton;

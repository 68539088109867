// src/components/StakedTokensDisplay.jsx
import React, { useContext, useEffect, useState } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import Typography from '@mui/material/Typography';
import StakedTokenCard from './StakedTokenCard';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ethers } from 'ethers';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card'; // **Added Import**

const ITEMS_PER_PAGE = 100;

const StakedTokensDisplay = () => {
  const { nftStakingContract, account, signer } = useContext(BlockchainContext);
  const [stakedTokenIds, setStakedTokenIds] = useState([]);
  const [displayedTokenIds, setDisplayedTokenIds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [unstaking, setUnstaking] = useState(false); // State for Unstaking All
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // For notifications

  // Fetch all staked tokens for the user
  const fetchStakedTokens = async () => {
    if (!nftStakingContract || !account) return;
    try {
      const tokenIds = await nftStakingContract.getStakedTokens(account);
      // Ensure tokenIds is an array
      if (Array.isArray(tokenIds)) {
        const numericTokenIds = tokenIds.map(id => id.toNumber());
        console.log("Fetched staked tokens:", numericTokenIds); // Debugging log
        setStakedTokenIds(numericTokenIds);
        setDisplayedTokenIds(numericTokenIds.slice(0, ITEMS_PER_PAGE));
        setHasMore(numericTokenIds.length > ITEMS_PER_PAGE);
      } else {
        console.error("getStakedTokens did not return an array:", tokenIds);
        setStakedTokenIds([]);
        setDisplayedTokenIds([]);
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error fetching staked tokens:", err);
      setError('Failed to fetch staked tokens.');
      setStakedTokenIds([]);
      setDisplayedTokenIds([]);
      setHasMore(false);
    }
    setLoading(false);
  };

  // Load more tokens when the user scrolls
  const fetchMoreData = () => {
    if (displayedTokenIds.length >= stakedTokenIds.length) {
      setHasMore(false);
      return;
    }
    // Simulate async fetch with a timeout
    setTimeout(() => {
      const nextItems = stakedTokenIds.slice(displayedTokenIds.length, displayedTokenIds.length + ITEMS_PER_PAGE);
      setDisplayedTokenIds(prev => [...prev, ...nextItems]);
      if (displayedTokenIds.length + nextItems.length >= stakedTokenIds.length) {
        setHasMore(false);
      }
    }, 500);
  };

  useEffect(() => {
    fetchStakedTokens();

    // Optional: Listen for events or set up polling to update staked tokens
    const interval = setInterval(() => {
      fetchStakedTokens();
    }, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftStakingContract, account]);

  // Handler for Unstake All button
  const handleUnstakeAll = async () => {
    if (!nftStakingContract || !signer || stakedTokenIds.length === 0) {
      setSnackbar({ open: true, message: 'No tokens to unstake or contracts not loaded.', severity: 'error' });
      return;
    }

    // Confirm with the user before proceeding
    const confirmUnstake = window.confirm("Are you sure you want to unstake all your NFTs?");
    if (!confirmUnstake) return;

    setUnstaking(true);

    try {
      for (const tokenId of stakedTokenIds) {
        const tx = await nftStakingContract.connect(signer).unstake(tokenId);
        await tx.wait(); // Wait for the transaction to be mined
        console.log(`Unstaked Token ID: ${tokenId}`);
      }
      setSnackbar({ open: true, message: 'All NFTs have been unstaked successfully!', severity: 'success' });
      // Refresh the staked tokens list
      fetchStakedTokens();
    } catch (err) {
      console.error("Error unstaking tokens:", err);
      setSnackbar({ open: true, message: 'Failed to unstake all NFTs.', severity: 'error' });
    }

    setUnstaking(false);
  };

  // Handler to close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Card sx={{ width: '100%', margin: 1, padding: 2, boxShadow: 'none' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" sx={{ padding: 2, textAlign: 'center' }}>
        {error}
      </Typography>
    );
  }

  if (stakedTokenIds.length === 0) {
    return (
      <Typography variant="h6" sx={{ padding: 2, textAlign: 'center' }}>
        You have no staked ♕ Block Sapiens ♕ NFTs.
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h2" gutterBottom>
        Your Staked ♕ Block Sapiens ♕ NFTs
      </Typography>

      {/* Unstake All Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button 
          variant="contained" 
          color="error" 
          onClick={handleUnstakeAll} 
          disabled={unstaking}
        >
          {unstaking ? 'Unstaking...' : 'Unstake All'}
        </Button>
      </Box>

      <InfiniteScroll
        dataLength={displayedTokenIds.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <CircularProgress />
          </Box>
        }
        endMessage={
          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', marginTop: 2 }}>
            You have seen all your staked NFTs.
          </Typography>
        }
      >
        <Grid container spacing={2} justifyContent="center">
          {displayedTokenIds.map(tokenId => (
            <Grid item xs={12} sm={6} md={6} key={tokenId}>
              <StakedTokenCard tokenId={tokenId} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>

      {/* Snackbar for Notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StakedTokensDisplay;

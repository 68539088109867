// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BlockchainProvider } from './contexts/BlockchainContext';
import ErrorBoundary from './components/ErrorBoundary'; // Ensure this exists or remove if not using
import './index.css'; // Import global styles
import './animations.css'; // Import animations
import theme from './theme'; // Import the custom theme

// Import Bangers font
import '@fontsource/bangers'; // Defaults to weight 400

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BlockchainProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BlockchainProvider>
  </React.StrictMode>
);

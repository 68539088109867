// src/components/TotalRewards.jsx
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import { Typography, Box, CircularProgress, Grid } from '@mui/material';
import { ethers } from 'ethers'; // Ensure ethers is imported for formatting

const TotalRewards = () => {
  const { nftStakingContract, account, provider } = useContext(BlockchainContext);
  const [totalRewards, setTotalRewards] = useState('0');
  const [erc20Symbol, setErc20Symbol] = useState('');
  const [loadingRewards, setLoadingRewards] = useState(true);
  const [loadingSymbol, setLoadingSymbol] = useState(true);
  const [error, setError] = useState('');

  // ERC20 ABI (Including 'symbol' function)
  const ERC20_ABI = [
    "function symbol() view returns (string)"
  ];

  // Initialize ERC20 Contract using useMemo to prevent re-creation on every render
  const erc20Contract = useMemo(() => {
    if (!nftStakingContract || !provider) return null;
    try {
      return new ethers.Contract(nftStakingContract.rewardToken(), ERC20_ABI, provider);
    } catch (err) {
      console.error("Error initializing ERC20 Contract:", err);
      return null;
    }
  }, [nftStakingContract, provider]);

  // Fetch ERC20 Symbol
  const fetchERCSymbol = async () => {
    if (!erc20Contract) return;
    try {
      const symbol = await erc20Contract.symbol();
      console.log("ERC20 Symbol:", symbol);
      setErc20Symbol(symbol);
    } catch (err) {
      console.error("Error fetching ERC20 symbol:", err);
      setError('Failed to fetch ERC20 symbol.');
    }
    setLoadingSymbol(false);
  };

  // Fetch Total Rewards
  const fetchTotalRewards = async () => {
    if (!nftStakingContract || !account) return;
    try {
      const tokenIds = await nftStakingContract.getStakedTokens(account);
      console.log("Staked Token IDs:", tokenIds);
      
      if (tokenIds.length === 0) {
        setTotalRewards('0');
        setLoadingRewards(false);
        return;
      }

      let total = ethers.BigNumber.from(0);
      
      // Fetch all rewards in parallel using Promise.all
      const rewards = await Promise.all(
        tokenIds.map(async (tokenId) => {
          const reward = await nftStakingContract.calculateReward(tokenId);
          console.log(`Reward for Token ID ${tokenId}:`, reward.toString());
          return reward || ethers.BigNumber.from(0);
        })
      );
      
      rewards.forEach((reward) => {
        total = total.add(reward);
      });

      const formattedTotal = ethers.utils.formatEther(total);
      setTotalRewards(formattedTotal);
    } catch (err) {
      console.error("Error fetching total rewards:", err);
      setError('Failed to load total rewards.');
    }
    setLoadingRewards(false);
  };

  useEffect(() => {
    if (erc20Contract) {
      fetchERCSymbol();
    }
  }, [erc20Contract]);

  useEffect(() => {
    fetchTotalRewards();
    const interval = setInterval(() => {
      fetchTotalRewards();
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [nftStakingContract, account]);

  const isLoading = loadingRewards || loadingSymbol;

  if (isLoading) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 1 }}>
          Loading Total Unclaimed Rewards...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: '1.5rem',
                sm: '2rem',
                md: '2.5rem',
              },
              textAlign: 'center',
              color: '#00bfff',
              wordWrap: 'break-word',
            }}
          >
            Total Unclaimed Rewards: {totalRewards} {erc20Symbol ? `$${erc20Symbol}` : ''}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TotalRewards;

// src/components/ApproveAllButton.jsx
import React, { useContext, useState } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import NeonButton from './NeonButton'; // Import the custom NeonButton
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const ApproveAllButton = () => {
  const { mockNFTContract } = useContext(BlockchainContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleApproveAll = async () => {
    if (!mockNFTContract) {
      setError("NFT Contract not loaded.");
      return;
    }
    setLoading(true);
    try {
      const tx = await mockNFTContract.setApprovalForAll(
        '0xC3F2c790C18ec7ec3E23D0927253D48cBb63D332', // Replace with your staking contract address
        true
      );
      await tx.wait();
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError('Approval Failed. Please try again.');
    }
    setLoading(false);
  };

  return (
    <>
      {/* Use the NeonButton instead of MUI's Button */}
      <NeonButton
        onClick={handleApproveAll}
        disabled={loading || !mockNFTContract}
        aria-label="Approve All NFTs for Staking"
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Approve All'}
      </NeonButton>

      {/* Success Snackbar */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
          variant="filled"
        >
          Approved Successfully!
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError('')}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApproveAllButton;

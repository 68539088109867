// src/components/StakeButton.jsx
import React, { useContext, useState } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import NeonButton from './NeonButton'; // Import the custom NeonButton
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const StakeButton = () => {
  const { nftStakingContract, mockNFTContract, account } = useContext(BlockchainContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleStake = async () => {
    if (!nftStakingContract || !mockNFTContract) {
      setError("Contracts not loaded.");
      return;
    }
    setLoading(true);
    try {
      // Fetch all NFTs owned by the user
      const balance = await mockNFTContract.balanceOf(account);
      const tokenIds = [];
      for (let i = 0; i < balance; i++) {
        const tokenId = await mockNFTContract.tokenOfOwnerByIndex(account, i);
        tokenIds.push(tokenId.toNumber());
      }

      if (tokenIds.length === 0) {
        setError('No NFTs to stake.');
        setLoading(false);
        return;
      }

      // Stake each token
      for (const tokenId of tokenIds) {
        const tx = await nftStakingContract.stake(tokenId);
        await tx.wait();
      }

      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError('Staking Failed. Please try again.');
    }
    setLoading(false);
  };

  return (
    <>
      {/* Use the NeonButton instead of MUI's Button */}
      <NeonButton
        onClick={handleStake}
        disabled={loading || !nftStakingContract || !mockNFTContract}
        aria-label="Stake All NFTs"
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Stake All NFTs'}
      </NeonButton>

      {/* Success Snackbar */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
          variant="filled"
        >
          Staked Successfully!
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError('')}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StakeButton;

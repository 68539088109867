// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: 'Bangers, cursive',
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3rem',
      },
    },
    h5: {
      fontFamily: 'Bangers, cursive',
      fontSize: '1.5rem', // Maintained original size
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: '1.1rem', // Increased size for multiplier and reward
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.3rem',
      },
    },
    body2: {
      fontSize: '0.9rem', // Smaller text, if needed elsewhere
    },
  },
});

export default theme;

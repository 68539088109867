// src/components/UnstakeButton.jsx
import React, { useContext, useState } from 'react';
import { BlockchainContext } from '../contexts/BlockchainContext';
import NeonButton from './NeonButton'; // Import the custom NeonButton
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const UnstakeButton = ({ tokenId }) => {
  const { nftStakingContract } = useContext(BlockchainContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleUnstake = async () => {
    if (!nftStakingContract) {
      setError("Staking Contract not loaded.");
      return;
    }
    setLoading(true);
    try {
      const tx = await nftStakingContract.unstake(tokenId);
      await tx.wait();
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError('Unstaking Failed. Please try again.');
    }
    setLoading(false);
  };

  return (
    <>
      {/* Use the NeonButton instead of MUI's Button */}
      <NeonButton
        onClick={handleUnstake}
        disabled={loading || !nftStakingContract}
        aria-label={`Unstake Token ID ${tokenId}`}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Unstake'}
      </NeonButton>

      {/* Success Snackbar */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
          variant="filled"
        >
          Unstaked Successfully!
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError('')}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UnstakeButton;

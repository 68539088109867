// src/components/NeonButton.jsx
import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#00bfff',
  color: '#fff',
  fontFamily: 'Bangers, cursive',
  fontSize: '1.2rem',
  padding: theme.spacing(1.5, 3),
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 191, 255, 0.6)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: '#00a0d1',
    boxShadow: '0 0 20px rgba(0, 191, 255, 1)',
    transform: 'scale(1.05)',
  },
}));

const NeonButton = ({ children, ...props }) => {
  return (
    <StyledButton className="neon-button" {...props}>
      {children}
    </StyledButton>
  );
};

export default NeonButton;
